/**
 * 将容器竖直滚动到指定位置
 * @param container 容器元素
 * @param distanceX 滚动距离X
 * @param distanceY 滚动距离Y
 * @param duration 间隔时间，默认为 0，即无动画效果
 * @returns {Promise}
 */
export function ScrollTo(container, distanceX = 0, distanceY = 0, duration = 0) {
    const initialX = container.scrollLeft;
    const initialY = container.scrollTop;
    const x = initialX + distanceX;
    const y = initialY + distanceY;
    const baseX = (initialX + x) * 0.5;
    const baseY = (initialY + y) * 0.5;
    const differenceX = initialX - baseX;
    const differenceY = initialY - baseY;
    const startTime = performance.now();
    return new Promise(resolve => {
      function step() {
        let normalizedTime = (performance.now() - startTime) / duration;
        if (normalizedTime > 1) {
          normalizedTime = 1;
        }
        container.scrollTo(baseX + differenceX * Math.cos(normalizedTime * Math.PI), baseY + differenceY * Math.cos(normalizedTime * Math.PI));
        if (normalizedTime < 1) {
          requestAnimationFrame(step);
        }
        else {
          resolve();
        }
      }
      requestAnimationFrame(step);
    });
  }
  
  /**
   * 获取元素在页面上的偏移量
   * @param {Element} element 元素
   * @returns {Object} position
   */
  function GetOffsetAwayFromDocument(element) {
    let top = 0;
    let left = 0;
    if (element.getBoundingClientRect) {
      const rect = GetBoundingClientRect(element);
      const body = document.body;
      const docElem = document.documentElement;
      const scrollTop = pageYOffset || docElem.scrollTop || body.scrollTop;
      const scrollLeft = pageXOffset || docElem.scrollLeft || body.scrollLeft;
      const clientTop = docElem.clientTop || body.clientTop;
      const clientLeft = docElem.clientLeft || body.clientLeft;
      top = rect.top + scrollTop - clientTop;
      left = rect.left + scrollLeft - clientLeft;
      return {
        // Math.round 兼容火狐浏览器
        top: Math.round(top),
        left: Math.round(left)
      };
    }
    else {
      while (element) {
        top += element.offsetTop;
        left += element.offsetLeft;
        element = element.offsetParent;
      }
      return {
        top,
        left
      };
    }
  }
  
  /**
   * 获取元素相对父元素的偏移量，默认设置了 position:relative 的父元素
   * @param {Element} element 元素
   * @param target
   * @returns {Object} position
   */
  export function GetOffsetAwayFromTarget(element, target) {
    // let top = 0;
    // let left = 0;
    if (!target){
      return {
        top: element.offsetTop,
        left: element.offsetLeft
      };
    }
    let elementOffset = GetOffsetAwayFromDocument(element);
    let targetOffset = GetOffsetAwayFromDocument(target);
  
    return {
      top: elementOffset.top + target.scrollTop - targetOffset.top,
      left: elementOffset.left + target.scrollLeft - targetOffset.left
    };
  }
  
  /**
   * 获取指定元素的边界（上、下、左、右）分别相对浏览器视窗边界的位置，这与原生不同，bottom和right是相对于视窗底部和右端的位置。
   * @param {HTMLElement} element
   * @returns {Object} top,lef,right,bottom,width,height
   * top：元素上边界距窗口最上面的距离
   * right：元素右边界距窗口最左边的距离
   * bottom：元素下边界距窗口最上面的距离
   * left：元素左边界距窗口最左边的距离
   * width：元素宽度
   * height：元素高度
   */
  function GetBoundingClientRect(element) {
    const rect = element.getBoundingClientRect();
    return {
      width: rect.width || rect.right - rect.left,
      height: rect.height || rect.bottom - rect.top,
      top: rect.top,
      right: rect.right,
      bottom: rect.bottom,
      left: rect.left
    };
  }