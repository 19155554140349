<template>
  <div style="align-self:center;">
    <div
      class="text-warning hover"
      style="font-size:12px;margin-right:1em"
      v-show="gameType!=='cn'"
      @click="showForm"
    >
      <Icon type="md-settings" style="font-size:14px;" />
      <span>设置阅读模式</span>
    </div>
    <Modal
      width="950"
      v-model="configIdiomCombForm"
      title="成语接龙阅读模式设置"
      :mask-closable="false"
      footer-hide
    >
      <ConfigPanel v-if="configIdiomCombForm" :gameType="'cnIdiomComb'" />
    </Modal>
    <Modal
      width="740"
      v-model="configFlyingForm"
      title="飞花令读模式设置"
      :mask-closable="false"
      footer-hide
    >
      <ConfigPanel v-if="configFlyingForm" :gameType="'cnFlying'" />
    </Modal>
  </div>
</template>

<script>
import ConfigPanel from "./ConfigPanel";
export default {
  props: {
    gameType: {}
  },
  components: { ConfigPanel },
  data() {
    return {
      configIdiomCombForm: false,
      configFlyingForm: false
    };
  },
  methods: {
    showForm() {
      if (this.gameType === "cnIdiomComb") {
        this.configIdiomCombForm = true;
      } else if (this.gameType === "cnFlying") {
        this.configFlyingForm = true;
      }
    }
  }
};
</script>

<style>
</style>
