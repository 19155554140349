<template>
  <Table :columns="columns.filter(ele => ele.types.includes(gameType))" :data="list">
    <template v-for="(col,i) in columns" :slot="col.slot" slot-scope="{ row, index }">
      <div :key="i">
        <template v-if="col.slot === 'lvl-range'">
          <template v-if="row.editing">
            <div style="width:170px">
              <Input
                class="input-number"
                type="number"
                v-model.number="addingData.minLevel"
                style="width:70px"
              />-
              <Input
                class="input-number"
                type="number"
                v-model.number="addingData.maxLevel"
                style="width:70px"
              />
            </div>
          </template>
          <template v-else>{{ row.minLevel }}-{{ row.maxLevel }}</template>
        </template>
        <template v-else-if="col.slot === 'opt'">
          <template v-if="row.editing">
            <span class="text-success hover" @click="submit(row, index)">确认</span>
            <span class="vui-g9 hover vui-f13" @click="cancel(row, index)">取消</span>
          </template>
          <template v-else>
            <Icon
              type="md-create"
              class="text-primary hover"
              size="16"
              @click="startModify(row, index)"
              title="编辑"
              style="margin-right:1em"
            />
            <Poptip confirm title="确定要删除吗?" @on-ok="del(row.id)" transfer>
              <Icon type="ios-trash" class="text-error hover" size="16" title="删除" />
            </Poptip>
          </template>
        </template>
        <template v-else>
          <div v-if="row.editing">
            <Input
              class="input-number"
              type="number"
              v-model.number="addingData[col.slot]"
              @on-change="onInputChange($event, addingData, col.slot)"
              style="width:82px"
            >
              <span
                slot="append"
                v-if="col.slot === 'readRate' || col.slot === 'listenRate' || col.slot === 'repeatRate'"
              >%</span>
            </Input>
          </div>
          <template v-else>{{ row[col.slot] }}</template>
        </template>
      </div>
    </template>

    <template slot="footer">
      <!--<div v-if="isAdding" style="display:flex;text-align:center;">
        <div style="width:120px">
          <Input v-model.number="addingData.minLevel" style="width:45px" />-
          <Input v-model.number="addingData.maxLevel" style="width:45px" />
        </div>
        <div
          v-for="(item,i) in columns.slice(1,columns.length-1).filter(ele => ele.types.includes(gameType))"
          :key="i"
          :style="{width:item.width+'px'}"
        >
          <Input style="width:calc(100% - 30px)" v-model.number="addingData[item.key]" />
        </div>
        <div style="width:165px;text-align:left;">
          <span
            class="text-success hover"
            style="margin:0 1em;padding:.25em .5em;"
            @click="submit"
          >确认</span>
          <Icon
            style="font-size:16px"
            class="text-error hover"
            type="md-remove-circle"
            @click="isAdding=false"
          />
        </div>
      </div>-->
      <div class="text-success hover" style="width:120px;" @click="startCreate" v-if="!isAdding">
        <Icon type="md-add-circle" />
        <span>新增设置</span>
      </div>
    </template>
  </Table>
</template>

<script>
import {
  addConfig,
  deleteConfig,
  getConfigs,
  modifyConfig,
} from "./services";
const getEmptyConfig = (gameType) => {
  return {
    listenCount: 0,
    listenRate: 0,
    repeatRate: 0,
    maxLevel: null,
    minLevel: null,
    readCount: 0,
    readRate: 0,
    solitaireCount: 0,
    randomCount: 0,
    type: gameType,
  };
};

export default {
  props: {
    gameType: {},
  },
  data() {
    return {
      columns: [
        {
          title: "指定等级",
          slot: "lvl-range",
          width: 170,
          types: ["cnIdiomComb", "cnFlying"],
        },
        {
          title: "接龙次数",
          slot: "solitaireCount",
          width: 102,
          types: ["cnIdiomComb"],
        },
        {
          title: "聆听遍数",
          slot: "listenCount",
          width: 102,
          types: ["cnIdiomComb"],
        },
        {
          title: "跟读遍数",
          slot: "readCount",
          width: 102,
          types: ["cnIdiomComb", "cnFlying"],
        },
        {
          title: "跟读比例",
          slot: "readRate",
          width: 112,
          types: ["cnIdiomComb", "cnFlying"],
        },
        {
          title: "聆听比例",
          slot: "listenRate",
          width: 112,
          types: ["cnIdiomComb", "cnFlying"],
        },
        {
          title: "随机句数",
          slot: "randomCount",
          width: 102,
          types: ["cnFlying"],
        },
        {
          title: "重复比例",
          slot: "repeatRate",
          width: 112,
          types: ["cnIdiomComb"],
        },
        {
          title: "操作",
          slot: "opt",
          width: 100,
          types: ["cnIdiomComb", "cnFlying"],
        },
      ],
      list: [],
      isAdding: false,
      addingData: {},
    };
  },
  methods: {
    onInputChange(val, row, key) {
      if (key === "readRate") {
        row.listenRate = 100 - row.readRate;
      } else if (key === "listenRate") {
        row.readRate = 100 - row.listenRate;
      }
    },
    verify(data) {
      const emt = getEmptyConfig(this.gameType);
      // 校验数据
      for (const key of Object.keys(emt)) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const value = data[key];
          if (
            value === "" ||
            Object.prototype.toString.call(value) === "[object Null]" ||
            Object.prototype.toString.call(value) === "[object Undefined]"
          ) {
            this.$Message.warning("数据填写不完整，请确认");
            return false;
          } else if (value < 0) {
            this.$Message.warning("部分数据不得小于0");
            return false;
          } else {
            if (key === "readRate" || key === "listenRate") {
              if (data.readRate + data.listenRate !== 100) {
                this.$Message.warning("跟读比例和聆听比例之和需等于100%！");
                return false;
              }
            }
          }
        }
      }
      return true;
    },
    startCreate() {
      this.list.push(
        Object.assign({}, getEmptyConfig(this.gameType), { editing: true })
      );
      this.list = this.list.map((x, i) => ({
        ...x,
        editing: i === this.list.length - 1,
      }));
      this.addingData = getEmptyConfig(this.gameType);
      this.isAdding = true;
    },
    startModify(item, index) {
      if (this.isAdding) {
        this.list.splice(this.list.length - 1, 1);
        this.isAdding = false;
      }
      this.list = this.list.map((x, i) => ({ ...x, editing: i === index }));
      this.addingData = Object.assign({}, item);
    },
    cancel(item, index) {
      if (!item.id) {
        // 新增
        this.list.splice(index, 1);
        this.isAdding = false;
      } else {
        // 编辑
        item.editing = false;
        this.$set(this.list, index, item);
      }
    },
    submit() {
      const s = this.verify(this.addingData);
      if (s) {
        const fn = this.addingData.id ? modifyConfig : addConfig;
        this.addingData.subject = "cn";
        fn(this.addingData).then(() => {
          this.isAdding = false;
          this.initList();
        });
      }
    },
    initList() {
      getConfigs(this.gameType).then((res) => {
        this.list = res.rows;
      });
    },

    del(id) {
      deleteConfig(id).then(() => {
        this.initList();
      });
    },
  },
  beforeMount() {
    this.addingData = getEmptyConfig(this.gameType);
    this.initList();
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ivu-input-group {
  top: 0;
}
</style>
