<template>
  <div style="align-self:center;">
    <div
      class="text-success hover"
      style="font-size:12px;"
      v-show="gameType!=='cn'"
      @click="showForm"
    >
      <Icon style="font-size:14px;" type="md-add-circle" />
      <span>创建{{{'cnIdiomComb':'成语接龙','cnFlying':'飞花令'}[gameType]}}</span>
    </div>
    <Modal v-model="showIdiomCombForm" title="创建成语接龙" width="90" footer-hide :mask-closable="false" >
      <IdiomCombForm v-if="showIdiomCombForm" @close="onClose" />
    </Modal>
    <Modal v-model="showFlyingForm" title="创建飞花令" footer-hide :mask-closable="false">
      <FlyingForm v-if="showFlyingForm" @close="onClose" />
    </Modal>
  </div>
</template>

<script>
import IdiomCombForm from "./IdiomCombForm";
import FlyingForm from "./FlyingForm";
export default {
  props: {
    gameType: {}
  },
  components: { IdiomCombForm, FlyingForm },
  data() {
    return {
      showIdiomCombForm: false,
      showFlyingForm: false
    };
  },
  methods: {
    showForm() {
      if (this.gameType === "cnIdiomComb") {
        this.showIdiomCombForm = true;
      } else if (this.gameType === "cnFlying") {
        this.showFlyingForm = true;
      }
    },
    onClose(flag){
      this.showIdiomCombForm = false;
      this.showFlyingForm = false;
      flag && this.$emit('close');
    }
  }
};
</script>

<style>
</style>
