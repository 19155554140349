<template>
  <ListFrame
    :getPageFn="getPage"
    :immediateInit="false"
    :viewConf="{showLvl:true, showTitle:true, showReorder:false}"
    :customedParams="customedParams"
  >
    <template slot="conditions" slot-scope="{requestFn}">
      <FirstCategory
        classification="game"
        class="typeSelect"
        v-model="customedParams.contentType"
        @input="requestFn()"
      />

      <ConfigFormButton
        style="width:120px; display:inline-block;"
        :gameType="customedParams.contentType"
        v-if="roleCode===0"
      />
      <ItemFormButton
        style="width:100px; display:inline-block;"
        :gameType="customedParams.contentType"
        v-if="roleCode===0"
        @close="requestFn()"
      />
    </template>
    <template slot-scope="{item,requestFn}">
      <TaskItem
        v-if="'cnIdiomComb'===item.contentType"
        :idomComb="item"
        style="margin-bottom:1em;flex:1"
      ></TaskItem>
      <FlyingShowItem v-else :data="item" type="0" @refresh="requestFn()"></FlyingShowItem>
    </template>
  </ListFrame>
</template>

<script>
import ItemFormButton from './ItemFormButton';
import ConfigFormButton from './ConfigFormButton';
import TaskItem from './TaskItem';
import { getHisTaskList, _configType } from './services';
import FlyingShowItem from './FlyingShowItem';
export default {
  props: {},
  components: { FlyingShowItem, ItemFormButton, ConfigFormButton, TaskItem },
  data() {
    return {
      customedParams: { contentType: 'cn' }
    };
  },
  computed: {
    roleCode() {
      return this.$store.getters.getRoleCode;
    }
  },
  methods: {
    getPage(params) {
      params.taskName = 0;
      params.category = params.contentType;
      params.gameType = _configType[params.contentType];
      return getHisTaskList(params).then((res) => {
        res.rows = res.rows.filter((ele) => !!ele);
        return res;
      });
    }
  }
};
</script>

<style scoped>
.typeSelect {
  width: 150px;
  margin-right: 20px;
  display: inline-block;
}
</style>
