<template>
  <div class="idiom-form">
    <div class="vui-row vui-align-items-center">
      <div class="vui-col-auto title vui-tr">指定等级</div>
      <div class="vui-col-fluid">
        <Input
          :disabled="!!formData || result.loading || !!result.idioms.length"
          type="number"
          :min="0"
          :max="10"
          v-model.number="form.grade.min"
          style="width: 88px"
        />-
        <Input
          :disabled="!!formData || result.loading || !!result.idioms.length"
          type="number"
          :min="0"
          :max="10"
          v-model.number="form.grade.max"
          style="width: 88px"
        />
      </div>
      <div v-if="result.lvlsError" class="text-warning">
        输入等级与已设置等级不符，请确认后重新输入！
      </div>
      <template v-else-if="result.config">
        <div class="vui-col-auto vui-tc">
          <div class="title">指定等级</div>
          {{ result.config.minLevel }} - {{ result.config.maxLevel }}
        </div>
        <div class="vui-col-auto vui-tc">
          <div class="title">接龙次数</div>
          {{ result.config.solitaireCount }}
        </div>
        <div class="vui-col-auto vui-tc">
          <div class="title">跟读遍数</div>
          {{ result.config.readCount }}
        </div>
        <div class="vui-col-auto vui-tc">
          <div class="title">重复比例</div>
          <span
            class="idiom-repeatrate"
            :class="{ warning: repeatRate > result.config.repeatRate }"
          >
            {{ repeatRate }}
          </span>
          /{{ result.config.repeatRate }}%
        </div>
      </template>
    </div>
    <div class="vui-row vui-align-items-center">
      <div class="vui-col-auto title vui-tr">成语</div>
      <div class="vui-col-fluid">
        <Input
          :disabled="!!formData || result.loading || !!result.idioms.length"
          v-model="form.idiom"
          style="width: 88px"
        />
      </div>
      <div class="vui-col-fluid">
        <Button
          type="primary"
          @click="reset"
          v-if="!result.loading && result.idioms.length"
          >重新输入</Button
        >
        <Button
          type="warning"
          @click="generate"
          v-if="!result.loading && !result.idioms.length"
          >生成组合</Button
        >
      </div>
    </div>
    <div class="vui-row" v-if="result.loading">
      <div class="vui-col-auto title"></div>
      <div class="vui-col-fluid loading">正在为您生成...</div>
    </div>
    <template v-else-if="result.idioms.length">
      <div class="vui-row">
        <div class="vui-col idiom-wrap" ref="scroll">
          <dl
            class="idiom-item"
            v-for="(item, index) in result.idioms"
            :key="index"
            :class="{ notallowd: !isSelectable(index), error: item.error }"
          >
            <dt>
              <ul>
                <li
                  v-for="(item2, i) in item.items"
                  :key="i"
                  :class="{ cur: item2.label === item.cur, used: item2.used }"
                  v-show="isVisible(index, item2)"
                  @click="select(index, item2, $event)"
                  :title="item2.used ? '该成语已使用过' : ''"
                >
                  {{ item2.label }}
                  <Icon type="md-warning" v-if="item2.used" />
                </li>
              </ul>
            </dt>
            <dd>
              <TagsInput :tags="item.tagList" style="max-width: 120px" />
            </dd>
          </dl>
        </div>
      </div>
      <div class="vui-row vui-align-items-center">
        <div class="vui-col-fluid">
          <Button type="primary" :disabled="form.uploading" @click="submit"
            >确认</Button
          >
        </div>
        <div class="vui-col-fluid">
          <Button :disabled="form.uploading" @click="cancel">取消</Button>
        </div>
      </div>
    </template>
    <div class="vui-row" v-else-if="result.error">
      <div class="text-warning" v-if="result.idomError" style="flex: 1">
        成语库中不存在“{{ idiom1st }}”，请确认后联系管理人员！
      </div>
    </div>
  </div>
</template>

<script>
import { getConfigs, queryCombCombination, createGame } from "./services";
import { GetOffsetAwayFromTarget, ScrollTo } from "./util";

export default {
  props: {
    formData: {},
  },
  data() {
    return {
      gameType: "3",
      form: {
        uploading: false,
        id: "",
        processId: "",
        parentId: "",
        contentType: "cnFlying",
        grade: {
          min: null,
          max: null,
        },
        idiom: "",
      },
      result: {
        loading: false,
        lvlsError: false,
        idomError: false,
        config: null,
        idioms: [],
      },
    };
  },
  computed: {
    repeatRate() {
      const usedNum = this.result.idioms.reduce((prev, cur) => {
        prev += cur.used ? 1 : 0;
        return prev;
      }, 0);
      return (
        (usedNum / (this.result.config.solitaireCount + 1)) *
        100
      ).toFixed(2);
    },
  },
  beforeMount() {
    if (this.formData) {
      this.form.grade.min = this.formData.minLevel;
      this.form.grade.max = this.formData.maxLevel;
      this.form.idiom = this.formData.title;
      this.loadData()
        .then(() => {
          this.idioms = this.formData.contentMaterialList[0].materialList
            .map((ele) => ele.content)
            .slice(1);
        })
        .catch((err) => {
          this.$Message.warning(err.message);
        });
    }
  },
  methods: {
    // 控制 label 是否显示
    isVisible(index, item) {
      if (index === 0) {
        return true;
      }
      if (this.result.idioms[index - 1].cur) {
        return (
          item.label[0] ===
          this.result.idioms[index - 1].cur[
            this.result.idioms[index - 1].cur.length - 1
          ]
        );
      }
      return true;
    },
    // 控制 label 是否可点击
    isSelectable(index) {
      if (index === 0) {
        return true;
      }
      return !!this.result.idioms[index - 1].cur;
    },
    // 选择 label
    select(index, item, event) {
      if (this.result.idioms[index].cur !== item.label) {
        const offset = GetOffsetAwayFromTarget(event.target, this.$refs.scroll);
        ScrollTo(
          this.$refs.scroll,
          offset.left -
            this.$refs.scroll.offsetWidth / 2 -
            this.$refs.scroll.scrollLeft,
          0,
          200
        );
        this.result.idioms[index].cur = item.label;
        this.result.idioms[index].used = item.used;
        this.result.idioms[index].tagList = item.tagList;
        this.result.idioms[index].error = false;
        for (index = index + 1; index < this.result.idioms.length; index++) {
          this.result.idioms[index].cur = null;
        }
      }
    },
    generate() {
      this.loadData().catch((err) => {
        this.$Message.warning(err.message);
      });
    },
    reset() {
      this.result.config = null;
      this.result.idioms = [];
    },
    cancel() {
      this.$emit("close");
    },
    // 提交
    submit() {
      // 验证是否已选择所有的成语
      let invalid = false;
      for (const value of this.result.idioms) {
        value.error = !value.cur;
        if (!invalid) {
          invalid = !value.cur;
        }
      }
      if (invalid) {
        this.$Message.warning("请确认所有成语均已勾选");
        return;
      }
      if (this.repeatRate > this.result.config.repeatRate) {
        this.$Message.warning("选择的成语超出复读比例");
        return;
      }
      const data = {
        title: this.form.idiom,
        gameType: this.gameType,
        contentType: "cnIdiomComb",
        minLevel: this.form.grade.min,
        maxLevel: this.form.grade.max,
        contentMaterialList: [
          {
            materialList: this.result.idioms.map((x) => ({
              content: x.cur,
              tagList: x.tagList,
            })),
          },
        ],
        pass: 10,
      };
      if (this.formData) {
        data.id = this.formData.id;
        data.processId = this.formData.processId;
      }
      this.form.uploading = true;
      data.taskName = 0;
      createGame(data)
        .then(() => {
          this.$Message.success("保存成功！");
          this.$emit("close", true);
        })
        .catch((err) => {
          this.$Message.warning(err.message);
        })
        .finally(async () => {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          this.form.uploading = false;
        });
    },
    loadData() {
      console.log(this.form.grade.min, this.form.grade.max);
      if (
        typeof this.form.grade.min !== "number" ||
        typeof this.form.grade.max !== "number" ||
        this.form.grade.min < 0 ||
        this.form.grade.max < this.form.grade.min
      ) {
        return Promise.reject(new Error("请输入正确的等级范围"));
      }

      // 验证输入
      if (!this.form.idiom) {
        return Promise.reject(new Error("请输入成语"));
      }

      this.result.loading = true;
      return Promise.all([
        // 获取配置
        getConfigs(this.gameType, this.form.grade.min, this.form.grade.max),
        // 获取成语
        queryCombCombination({
          content: this.form.idiom,
          minLevel: this.form.grade.min,
          maxLevel: this.form.grade.max,
        }),
      ])
        .then((res) => {
          const [data1, data2] = res;
          this.result.config = data1.rows[0];
          const idioms = [];
          this.strutreeAnalysis(
            [data2],
            (childNode, index, parentNodes, hierarchy) => {
              // console.log('_______'.repeat(parentNodes.length) + childNode.label + hierarchy);
              if (idioms.length <= hierarchy) {
                idioms.push({
                  items: [
                    {
                      label: childNode.label,
                      used: childNode.used,
                      tagList: childNode.tagList || [],
                    },
                  ],
                  cur: childNode.label,
                  used: childNode.used,
                  tagList: childNode.tagList,
                  error: false,
                });
              } else {
                idioms[hierarchy].items.push({
                  label: childNode.label,
                  tagList: childNode.tagList || [],
                });
                if (!idioms[hierarchy].cur) {
                  idioms[hierarchy].cur = childNode.label;
                  idioms[hierarchy].tagList = childNode.tagList;
                }
              }
              return true;
            }
          );
          this.result.idioms = idioms;
          if (
            this.result.idioms.length - 1 <
            this.result.config.solitaireCount
          ) {
            this.result.idioms = [];
            this.$Message.warning("没有符合条件的接龙！");
            this.result.idomError = true;
          }
        })
        .catch((err) => {
          return Promise.reject(new Error(err));
        })
        .finally(async () => {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          this.result.loading = false;
        });
    },
    // 遍历节点
    strutreeAnalysis(nodes, callback) {
      // 广度遍历
      const every = (nodes) => {
        let continued = true;
        const nextNodes = [];
        for (const { children, hierarchy, parentNodes } of nodes) {
          for (const [index, childNode] of children.entries()) {
            continued =
              !callback || callback(childNode, index, parentNodes, hierarchy);
            if (continued) {
              nextNodes.push({
                parentNodes: [...parentNodes, childNode],
                children: childNode.children,
                hierarchy: hierarchy + 1,
              });
            } else {
              break;
            }
          }
          if (!continued) {
            break;
          }
        }
        if (continued && nextNodes.length) {
          continued = every(nextNodes);
        } else {
          return false;
        }
        return continued;
      };

      every(
        nodes.map((node) => ({
          parentNodes: [],
          children: [node],
          hierarchy: 0,
        }))
      );
    },
  },
};
</script>

<style lang="less" scoped>
.idiom-form {
  .title {
    width: 66px;
  }

  .loading {
    font-style: italic;
    color: #999;
  }

  .ivu-input-wrapper {
    margin: 5px 0;
  }
}

.idiom-wrap {
  overflow-x: auto;
  white-space: nowrap;
}

.idiom-item {
  display: inline-block;
  margin: 0 10px 10px 0;

  dt {
    border: 1px solid #ddd;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
  }

  dd {
    margin-top: 10px;
  }

  &.notallowd {
    cursor: not-allowed;

    li {
      pointer-events: none;
    }
  }

  &.error {
    dt {
      border-color: orangered;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    height: 182px;
    overflow: auto;

    li {
      display: flex;
      align-items: center;
      padding: 0 20px;
      line-height: 26px;
      cursor: pointer;
      white-space: nowrap;
      transition: border-color 0.1s, background-color 0.1s, color 0.1s;

      &.cur {
        background-color: #2d8cf0;
        color: #fff;
      }

      &.used {
        color: #666;

        &.cur {
          background-color: #ffe1e1;
        }

        .ivu-icon {
          margin-left: 2px;
          font-size: 12px;
          color: orangered;
        }
      }

      &:not(.cur):hover {
        background-color: #eee;
      }
    }
  }
}

.idiom-repeatrate {
  color: green;

  &.warning {
    color: orangered;
  }
}
</style>
